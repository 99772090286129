import React, { useCallback, useMemo, useRef, useState } from "react";
import { SubmitHandler, useForm } from "react-hook-form";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useOnClickOutside } from "usehooks-ts";

import Mark from "components/grades/Mark";
import Loader from "components/common/Loader";
import Popup from "components/common/Popup";
import Modal from "components/common/Modal";

import { DEFAULT_MARKS, PRE_K_TEMPLATE_MARKS } from "utils/constants/grade";
import { INPUT_STYLE, SUBMIT_STYLE } from "utils/styles";
import {
  GRADE,
  GRADE_PARAMS,
  REPORT_ITEM,
  REPORT_ITEM_DATA,
  STATS_ATTENDANCE
} from "utils/types/grade";

interface Props {
  grade: GRADE;
  data: any[];
  formData: any;
  params: GRADE_PARAMS;
  isSmall: boolean;
  subjects: string[];
  attendance: STATS_ATTENDANCE;
  callback: (value: GRADE) => void;
  canEdit: boolean;
}

function ReportCard(props: Props) {
  const {
    grade,
    data,
    formData,
    params,
    isSmall,
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    subjects,
    attendance,
    callback,
    canEdit
  } = props;
  const reportRef = useRef<HTMLDivElement>(null);

  const [expand, setExpand] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);

  const {
    register,
    handleSubmit,
    watch,
    reset,
    formState: { isSubmitting, isDirty }
  } = useForm<any>({ defaultValues: formData });

  const marks = useMemo(() => {
    if (isSmall) {
      return PRE_K_TEMPLATE_MARKS;
    }
    return DEFAULT_MARKS;
  }, [isSmall]);

  const onSubmit: SubmitHandler<any> = useCallback(
    async (submittedData) => {
      const gradeReportToSend = data.map(
        (reportItem: REPORT_ITEM, iRI: number) => {
          if ((reportItem as any).comments !== undefined) {
            return {
              ...reportItem,
              comments: submittedData[iRI].comments
            };
          }
          if (reportItem.data) {
            const updatedData = reportItem.data.map(
              (reportItemData: REPORT_ITEM_DATA, iRID: number) => {
                return {
                  ...reportItemData,
                  mark: submittedData[iRI].data[iRID].mark,
                  skill: submittedData[iRI].data[iRID].skill
                };
              }
            );

            return {
              ...reportItem,
              data: updatedData
            };
          }
        }
      );

      callback({
        ...grade,
        reportData: gradeReportToSend
      });
    },
    [data, grade]
  );

  const handleClickOutsideReport = useCallback(() => {
    if (isDirty && expand && canEdit) {
      setShowModal(true);
    }
  }, [isDirty, showModal, expand, canEdit]);

  useOnClickOutside(reportRef, handleClickOutsideReport);

  return (
    <>
      <div ref={reportRef} className='rounded-lg shadow-lg bg-white p-4'>
        <button
          onClick={() => {
            if (expand && isDirty) {
              setShowModal(true);
            }
            setExpand(!expand);
          }}
          className='w-full flex items-center justify-between'
        >
          <span className='font-bold text-lg text-left'>{`Report card Term ${params.term.termNb} - ${params.student.name} - ${params.student.level} - ${params.schoolYear}`}</span>
          <FontAwesomeIcon icon={faChevronDown} />
        </button>
        <div className={`${expand ? "block" : "hidden"} py-12 px-0`}>
          <div className='grid grid-cols-1 lg:grid-cols-2 pb-4'>
            <div
              id='report-header'
              className='flex items-center flex-wrap pb-6 lg:pb-0'
            >
              <div id='report-logo' className='mx-auto mb-3 md:mx-3 xl:mb-0'>
                <img src='/images/logo.png' width={80} height={93} />
              </div>
              <div className='flex flex-col justify-center text-slate-600 pl-0 xl:pl-6'>
                <p className='font-bold'>Elementary School of Antananarivo</p>
                <p>Amboara Ambohitrarahaba</p>
                <p>contact@esa-mada.com</p>
                <p>+261 34 40 216 81</p>
                <p>NIF: 4003517556 - STAT: 11 2019 0 05 848</p>
              </div>
            </div>
            <div id='report-infos'>
              <div className='flex lg:justify-between'>
                <p className='font-bold mr-3 lg:mr-0'>Name:</p>
                <p>{params.student.name}</p>
              </div>
              <div className='flex lg:justify-between'>
                <p className='font-bold mr-3 lg:mr-0'>Level:</p>
                <p>{params.student.level}</p>
              </div>
              <div className='flex lg:justify-between'>
                <p className='font-bold mr-3 lg:mr-0'>Term:</p>
                <p>{`Term ${params.term.termNb}`}</p>
              </div>
              <div className='flex lg:justify-between'>
                <p className='font-bold mr-3 lg:mr-0'>School Year:</p>
                <p>{params.schoolYear}</p>
              </div>
            </div>
          </div>
          <hr />
          <p className='pt-4'>
            <span className='font-bold mr-3'>Delays :</span>
            {attendance.delays}
          </p>
          <p className='pb-4'>
            <span className='font-bold mr-3'>Absences :</span>
            {attendance.absences}
          </p>
          <hr />

          <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col'>
            <div
              className={`grid grid-cols-1 ${isSmall ? "" : "lg:grid-cols-2 gap-6"}`}
            >
              {data
                .slice(0, -1)
                .map((reportItem: REPORT_ITEM, reportItemIndex: number) => (
                  <div
                    key={
                      reportItem.subject ? reportItem.subject : Math.random()
                    }
                  >
                    <p className='font-bold bg-slate-100 text-center py-3'>
                      {reportItem.subject}
                    </p>
                    <p className='p-2 italic border-x border-b font-semibold'>
                      The student is able to
                    </p>
                    {reportItem.data.map(
                      (
                        reporItemData: REPORT_ITEM_DATA,
                        reportItemDataIndex: number
                      ) => (
                        <div
                          key={`${reporItemData.skill}${reportItemIndex}${reportItemDataIndex}`}
                          className='grid grid-cols-2'
                        >
                          <div className='border border-t-0 border-r-0'>
                            <textarea
                              disabled={isSubmitting || !canEdit}
                              className={`${INPUT_STYLE} disabled:cursor-not-allowed disabled:resize-none`}
                              placeholder='Skill'
                              {...register(
                                `${reportItemIndex}.data.${reportItemDataIndex}.skill` as any
                              )}
                            ></textarea>
                          </div>
                          <div className='border border-t-0'>
                            <textarea
                              disabled={isSubmitting || !canEdit}
                              className={`${INPUT_STYLE} disabled:cursor-not-allowed disabled:resize-none`}
                              placeholder='Mark'
                              {...register(
                                `${reportItemIndex}.data.${reportItemDataIndex}.mark` as any
                              )}
                            ></textarea>
                          </div>
                        </div>
                      )
                    )}
                  </div>
                ))}
            </div>
            <p className='font-bold pt-6'>Comments</p>
            <textarea
              disabled={isSubmitting || !canEdit}
              className={`${INPUT_STYLE} border rounded-lg disabled:cursor-not-allowed min-h-40 disabled:resize-none`}
              placeholder='Comments'
              {...register(`${data.length - 1}.comments` as any)}
            ></textarea>

            <div className='w-full max-w-full lg:max-w-[50%] mt-12 border'>
              <Mark marks={marks} />
            </div>

            {canEdit && (
              <div
                id='btnSubmit'
                className='w-full flex items-center justify-center pt-6'
              >
                {isSubmitting ? (
                  <div className={`${SUBMIT_STYLE} bg-gray-100`}>
                    <Loader width='w-6' height='h-6' text='text-gray-300' />
                  </div>
                ) : (
                  <input
                    type='submit'
                    disabled={isSubmitting}
                    value='Save report card'
                    className={`${SUBMIT_STYLE} hover:bg-esaMinty bg-esaMintyDark text-white font-bold`}
                  />
                )}
              </div>
            )}
          </form>
        </div>
      </div>

      {showModal && (
        <Modal
          isOpen={showModal}
          onClose={() => setShowModal(false)}
          title='Warning'
          className='md:max-w-[500px] lg:max-w-[500px]'
          hideClose
        >
          <Popup
            onConfirm={() => {
              const currentFormData = watch();
              onSubmit(currentFormData);
              setShowModal(false);
            }}
            onClose={() => {
              reset();
              setShowModal(false);
            }}
            text='Do you want to save changes to the Report card ?'
            textConfirm='Save report card'
            textCancel='Discard changes'
            iconColor='text-amber-500'
            confirmColor='hover:bg-esaMinty bg-esaMintyDark'
          />
        </Modal>
      )}
    </>
  );
}

export default ReportCard;
