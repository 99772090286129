import {
  PRE_K_TEMPLATE_REPORT_CARD,
  PRE_K_TEMPLATE_SUBJECTS
} from "utils/constants/grade";

export const getGradesForExistingSubjects = (
  type: string,
  resLevel,
  resGrades,
  emptyGrades
) => {
  const data = resLevel.subjects.map((subjectName: string) => {
    const subjectExistForThisLevel = resLevel.subjects.includes(subjectName);

    let recordOnDb = [];
    let emptyRecord = [];

    if (type === "quizz") {
      recordOnDb = resGrades.gradeQuiz.filter((a) => a.subject === subjectName);

      emptyRecord = emptyGrades.emptyQuizzGrades.filter(
        (a) => a.subject === subjectName
      );
    }
    if (type === "exam") {
      recordOnDb = resGrades.gradeExam.filter((a) => a.subject === subjectName);

      emptyRecord = emptyGrades.emptyExamGrades.filter(
        (a) => a.subject === subjectName
      );
    }

    if (subjectExistForThisLevel) {
      return recordOnDb.length === 0 ? emptyRecord[0] : recordOnDb[0];
    }
  });

  return unificationOfIds(data);
};

export const unificationOfIds = (array) => {
  const result = [];

  for (let index = 0; index < array.length; index++) {
    const element = array[index];
    delete element.id;

    result.push({
      ...element,
      id: index
    });
  }

  return result;
};

export const getReportCardsForExistingSubjects = (
  isSmallClasses,
  resLevel,
  resGrades,
  emptyGrades
) => {
  return isSmallClasses
    ? getReportCardsForSmallKids(resGrades)
    : getReportCardForBigKids(resLevel, resGrades, emptyGrades);
};

const getReportCardForBigKids = (resLevel, resGrades, emptyGrades) => {
  const data = resLevel.subjects.map((subjectName: string) => {
    const subjectExistForThisLevel = resLevel.subjects.includes(subjectName);

    const recordOnDb = resGrades.reportData.filter(
      (a) => a.subject === subjectName
    );

    const emptyRecord = emptyGrades.emptyReportData.filter(
      (a) => a.subject === subjectName
    );

    if (subjectExistForThisLevel) {
      return recordOnDb.length === 0 ? emptyRecord[0] : recordOnDb[0];
    }
  });

  const comments =
    resGrades.reportData.length > 0
      ? resGrades.reportData[resGrades.reportData.length - 1].comments
      : "";

  const result = unificationOfIds(data);
  result.push({ comments });

  // console.log(result);
  return result;
};

const getReportCardsForSmallKids = (resGrades) => {
  return resGrades.reportData === undefined || resGrades.reportData.length === 0
    ? PRE_K_TEMPLATE_REPORT_CARD
    : resGrades.reportData[0].subject !== PRE_K_TEMPLATE_SUBJECTS[0]
      ? PRE_K_TEMPLATE_REPORT_CARD
      : resGrades.reportData;
};
